import React, { useEffect } from "react";
import { UnauthenticatedHome } from "~/components/unauthenticatedHome";
import { AuthenticatedHome } from "~/components/authenticatedHome";

import { AnimatePresence, motion } from "framer-motion";
import { useInterval } from "react-use";
import NavBar from "~/components/navbar";
import { getCookie } from "~/utils/cookie";
import { CoolCatsModal } from "~/components/coolCatsModal";
import { useRouter } from "next/router";

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
  },
  hidden: {
    opacity: 0,
    scale: 2,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
  },
};

const Pending = ({ visible, onFinishPending, setReady }) => {
  const [count, setCount] = React.useState(3);
  useInterval(() => setCount((current) => current - 1), 850);

  useEffect(() => {
    if (count === 1) setReady(true);
    if (!count) onFinishPending();
  }, [count]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          key="pending"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={{
            exit: { opacity: 0, scale: 2 },
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          style={{ zIndex: 100 }}
          className="fixed inset-0 z-50 w-screen h-screen min-h-screen bg-black min-h-screen-ios h-screen-ios flex-center"
        >
          <AnimatePresence>
            {[...new Array(3)].map(
              (_, ndx) =>
                ndx + 1 === count && (
                  <motion.img
                    key={ndx}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variants}
                    className="absolute w-36"
                    src={`/assets/badges/${count}.png`}
                  />
                )
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default function Home() {
  const [pending, setPending] = React.useState(true);
  const [ready, setReady] = React.useState(false);
  const { push } = useRouter();

  const savedToken = React.useMemo(() => getCookie("token"), []);

  if (savedToken) {
    return (
      <>
        <NavBar />
        <AuthenticatedHome />
        <CoolCatsModal />
      </>
    );
  }

  return (
    <>
      <nav
        as="nav"
        className="sticky top-0 z-50 py-2 bg-white border-b border-gray-200"
      >
        <div className="justify-between px-4 py-2 mx-auto lg:px-8 flex-center">
          <img
            className="block w-auto h-6 lg:h-8"
            src="/assets/logo-black.png"
            alt="Ladder"
          />
          <div
            style={{
              background:
                "linear-gradient(90deg, #E97B88 0%, #78AAFF 56.25%, #A280ED 100%)",
              boxShadow:
                "0px 0.564654px 1.12931px rgba(0, 0, 0, 0.45), 0px 0.564654px 9.14px rgba(255, 255, 255, 0.7)",
            }}
            className="p-1 transition-transform rounded-full hover:scale-110"
          >
            <button
              onClick={() => push("/auth/apple")}
              className="px-6 py-2 space-x-2 text-sm text-white transition-colors bg-black rounded-full flex-center md:px-8 md:text-base hover:bg-gray-900"
            >
              <img
                src="/assets/external/ethereum-logo-sm.png"
                className="w-5"
              />
              <span>GET STARTED</span>
            </button>
          </div>
        </div>
      </nav>
      <UnauthenticatedHome />
    </>
  );
}
