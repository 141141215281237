import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { Badge } from "~/components/badge";
import { useMyBadges } from "~/hooks/useMyBadges";
import { CTAs } from "../navbar";
import * as Sentry from "@sentry/node";
import {
  COMPLETE_STATE,
  DEFAULT_STATE,
  ERROR_STATE,
  PENDING_STATE,
  useNotification,
} from "~/containers/notification";
import { useWeb3React } from "@web3-react/core";
import { getCookie, removeCookie, setCookie } from "~/utils/cookie";
import axios from "axios";
import { useAppState } from "~/containers/app";

const filters = {
  ALL: 0,
  CLAIMED: 1,
  UNCLAIMED: 2,
};

const MAX_BATCH_MINT = 50;

const NavButton = ({ children, active, ...props }) => {
  return (
    <button
      {...props}
      className={cx("rounded-lg px-2 py-1", {
        "bg-transparent opacity-75": !active,
        "bg-warm-gray font-bold": active,
      })}
    >
      {children}
    </button>
  );
};

const ClaimAllButton = ({ completionNumber = 0, setLocalLoading }) => {
  const { chainIdIsValid } = useAppState();
  const [batchLoading, setBatchLoading] = useState(false);
  const token = React.useMemo(() => getCookie("token"), []);
  const { account: member } = useWeb3React();
  const { setState: setNotificationState, state: notificationState } =
    useNotification();

  useEffect(() => {
    if (notificationState === COMPLETE_STATE) {
      setBatchLoading(false);
    }
    if (notificationState === PENDING_STATE) {
      setBatchLoading(true);
    }
  }, [notificationState]);

  const { data: nfts = [] } = useMyBadges();

  const total = useMemo(
    () => completionNumber - (nfts?.length || 0),
    [completionNumber, nfts]
  );

  const completedIds = [...new Array(completionNumber)].map(
    (_, ndx) => ndx + 1
  );

  const totalDifference = completedIds.filter((x) => !nfts?.includes?.(x));
  const difference = totalDifference.slice(0, MAX_BATCH_MINT);

  const setLoading = (value) => {
    const dictionary = {};
    if (!value) {
      removeCookie(`transaction-hash`, value);
      difference.map((id) => {
        removeCookie(`badge-${id}-hash`, value);
        dictionary[id] = value;
      });
    } else {
      setCookie(`transaction-hash`, value);
      difference.map((id) => {
        setCookie(`badge-${id}-hash`, value);
        dictionary[id] = value;
      });
    }
    setLocalLoading((current) => ({ ...current, ...dictionary }));
  };

  const mintBatch = async () => {
    try {
      setLoading(true);
      setNotificationState(PENDING_STATE);

      const result = await axios.post(
        "/api/mintBadgeBatch",
        {
          account: member,
          ids: difference,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(result.data.hash);
      setNotificationState(DEFAULT_STATE);
      setNotificationState(PENDING_STATE);
    } catch (error) {
      console.error(error);
      setNotificationState(ERROR_STATE);
      setLoading(false);
    }
  };

  if (!total) return null;

  const disabled = !chainIdIsValid || batchLoading;

  return (
    <div
      style={{
        padding: 3,
        background:
          "linear-gradient(90deg, #E97B88 0%, #78AAFF 56.25%, #A280ED 100%)",
        boxShadow:
          "0px 0.564654px 1.12931px rgba(0, 0, 0, 0.45), 0px 0.564654px 9.14px rgba(255, 255, 255, 0.7)",
      }}
      className="w-full p-1 transition-transform rounded-full md:w-auto hover:scale-105"
    >
      <button
        onClick={mintBatch}
        disabled={disabled}
        className={cx(
          "px-6 py-2 text-sm text-white transition-colors bg-black rounded-full md:px-8 md:text-base hover:bg-gray-900",
          {
            "cursor-not-allowed opacity-75": disabled,
          }
        )}
      >
        Claim {total > MAX_BATCH_MINT ? `${MAX_BATCH_MINT}` : `all (${total})`}
      </button>
    </div>
  );
};

export function AuthenticatedHome() {
  const { currentUser } = useCurrentUser();
  const [filter, setFilter] = useState(filters.ALL);
  const { data: nfts, isLoading, isLoadingError, refetch } = useMyBadges();
  const [localLoading, setLocalLoading] = useState({});

  const qrCodeFileName = useMemo(() => {
    if (currentUser?.membershipStatus !== "onboarding") {
      return "ladder-app-link-qr.png";
    }
    return "web-quiz-qr.png";
  }, [currentUser?.membershipStatus]);

  const completionNumber = currentUser?.completionStats?.completionNumber || 0;

  return (
    <div className="container flex justify-center px-2 py-4 mx-auto md:px-4 max-w-7xl">
      <div className="my-12 md:p-4">
        <div className="max-w-2xl mx-auto space-y-4">
          <h1 className="text-3xl text-center text-gray-900 uppercase lg:text-4xl font-display">
            Proof Of Workout NFTS
          </h1>
          <p className="text-center text-gray-500 text-md ">
            Some NFTs can’t be bought. No matter how much ETH you have. They
            have to be earned. Complete workouts with your coach in the{" "}
            <b className="text-black">Ladder app</b> to earn your NFTs
          </p>
        </div>
        <div className="my-12 space-x-2 flex-center">
          <NavButton
            onClick={() => setFilter(filters.ALL)}
            active={filter === filters.ALL}
          >
            All
          </NavButton>
          <NavButton
            onClick={() => setFilter(filters.CLAIMED)}
            active={filter === filters.CLAIMED}
          >
            Claimed
          </NavButton>
          <NavButton
            onClick={() => setFilter(filters.UNCLAIMED)}
            active={filter === filters.UNCLAIMED}
          >
            Unclaimed
          </NavButton>
        </div>
        <div className="justify-between w-full px-4 flex-center">
          <span className="block text-gray-500 text-md ">
            {nfts?.length || 0}/{completionNumber || 0} claimed
          </span>
          <div className="block md:hidden">
            <CTAs />
          </div>
          <div className="hidden md:block">
            <ClaimAllButton
              setLocalLoading={setLocalLoading}
              completionNumber={completionNumber}
              nfts={nfts}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 pt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div className="hidden md:block">
            <div className="overflow-hidden border rounded-xl">
              <div className="relative flex-col bg-gray-800 group flex-center">
                <img
                  src={`/assets/${qrCodeFileName}`}
                  className="block w-full px-8 py-8 overflow-hidden rounded-lg"
                  alt="Scan to open Ladder"
                />
                <p className="absolute bottom-0 mt-1 mb-2 text-sm text-gray-100">
                  Scan me!
                </p>
              </div>
            </div>
            <div className="py-2">
              <button className="flex items-center space-x-2 font-bold text-gray-900">
                <img src="/assets/logo-sm.png" className="w-5 h-5" />
                <span>Open the Ladder app</span>
              </button>
            </div>
          </div>
          {[...new Array(completionNumber)].map((_, ndx) => {
            const claimedId = nfts?.find?.((ID) => ID === ndx + 1);
            const workoutId = claimedId || ndx + 1;

            const renderBadge = () => (
              <Badge
                key={ndx + (nfts?.length || 0)}
                loading={
                  (isLoading && !nfts?.length) || localLoading[workoutId]
                }
                isLoadingError={isLoadingError}
                workoutId={workoutId}
                claimed={!!claimedId}
                available={workoutId <= 250}
                setLocalLoading={(value) => {
                  setLocalLoading((current) => ({
                    ...current,
                    [workoutId]: value,
                  }));
                }}
              />
            );
            switch (filter) {
              case filters.CLAIMED:
                return claimedId && renderBadge();
              case filters.UNCLAIMED:
                return !claimedId && renderBadge();
              case filters.ALL:
              default:
                return renderBadge();
            }
          })}
          {filters.ALL === filter && completionNumber > 0 && (
            <Badge
              workoutId={completionNumber + 1}
              claimed={false}
              available={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
