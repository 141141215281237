import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAppState } from "~/containers/app";
import { isMobile } from "~/utils/helpers";
import { setCookie } from "~/utils/cookie";

export function CoolCatsModal() {
  const { coolCatsModalOpen: open = false, setCoolCatsModalOpen: setOpen } =
    useAppState();

  const handleClose = () => {
    setCookie("hasShownCoolCatsPrompt", 1);
    setOpen(false);
  };
  const handleLikeCats = () => {
    setCookie("hasShownCoolCatsPrompt", 1);
    window.open("/coolcatowner", "_blank");
  };

  return (
    <Transition.Root show={open}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
        style={{ zIndex: 200 }}
      >
        <div className="relative min-h-screen px-4 pt-4 text-center flex-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full">
                  <img
                    src="/assets/coolcat.png"
                    className="w-16 rounded-full shadow-lg"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are You a Coolcat Owner?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      We love the Coolcat community, and if you&apos;re a member
                      too we want to give you a month free of Ladder!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 space-x-4 sm:mt-6 flex-center">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-gray-800 border border-transparent rounded-full shadow-sm bg-warm-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleClose}
                >
                  No :(
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-full shadow-s focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleLikeCats}
                >
                  I like the cats
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
