import cx from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { getBadgeImageUrl } from "~/utils/getBadgeImageUrl";
import axios from "axios";
import { getCookie, removeCookie, setCookie } from "~/utils/cookie";
import {
  COMPLETE_STATE,
  DEFAULT_STATE,
  ERROR_STATE,
  PENDING_STATE,
  useNotification,
} from "~/containers/notification";
import { useWeb3React } from "@web3-react/core";
import { useAppState } from "~/containers/app";

import { useQuery } from "react-query";

export const Badge = ({
  workoutId,
  claimed,
  available,
  loading,
  isLoadingError,
}) => {
  const token = React.useMemo(() => getCookie("token"), []);

  const [localLoading, setLocalLoading] = useState(false);
  const hash = useMemo(
    () => getCookie(`badge-${workoutId}-hash`),
    [workoutId, localLoading]
  );

  const { account: member } = useWeb3React();
  const { chainIdIsValid } = useAppState();

  const [_error, setError] = React.useState(false);
  const { setState: setNotificationState, state: notificationState } =
    useNotification();

  const setLoading = (value) => {
    if (!value) {
      removeCookie(`badge-${workoutId}-hash`);
      removeCookie(`transaction-hash`);
    } else {
      setCookie(`badge-${workoutId}-hash`, value);
      setCookie(`transaction-hash`, value);
    }

    setLocalLoading(!!value);

    //trigger state change
    setNotificationState(DEFAULT_STATE);
    setNotificationState(PENDING_STATE);
  };

  const error = _error || isLoadingError || notificationState === ERROR_STATE;

  const { data: transactionStatus } = useQuery(
    ["getTransactionReceipt", hash],
    async () => {
      if (!hash || typeof hash !== "string") return;
      if (["true", "false"].includes(hash)) return;

      const response = await fetch(`/api/getTransactionReceipt/${hash}`);

      if (response.status !== 200) {
        throw new Error("unable to fetch badges");
      }

      const { status } = await response.json();

      return status;
    },
    {
      retry: true,
      retryDelay: 5000,
    }
  );

  useEffect(() => {
    if ("undefined" === typeof transactionStatus) return;

    if (Number(transactionStatus) === 0) {
      setLoading(false);
      setNotificationState(ERROR_STATE);
    } else if (Number(transactionStatus) === 1) {
      setLoading(false);
      setNotificationState(COMPLETE_STATE);
    }
  }, [transactionStatus]);

  const completeWorkout = async () => {
    try {
      if (!workoutId) {
        throw new Error("missing workoutId number");
      }
      setNotificationState(PENDING_STATE);
      setLoading(true);

      const result = await axios.post(
        "/api/mintBadge",
        {
          account: member,
          id: workoutId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log({ workoutId, result: result.data });

      setLoading(result.data.hash);
      setNotificationState(PENDING_STATE);
    } catch (error) {
      console.error(error);
      setError(true);
      setNotificationState(ERROR_STATE);
      setLoading(false);
    }
  };

  const openSeaUrl = useMemo(() => {
    if (Number(process.env.NEXT_PUBLIC_CHAIN_ID) === 137) {
      return `https://opensea.io/assets/matic/${process.env.NEXT_PUBLIC_CONTRACT_ADDRESS}/${workoutId}`;
    }
    return `https://testnets.opensea.io/assets/mumbai/${process.env.NEXT_PUBLIC_CONTRACT_ADDRESS}/${workoutId}`;
  }, [workoutId]);

  const disabled =
    error || loading || !!hash || localLoading || !member || !chainIdIsValid;

  return (
    <div className="group">
      <div className="overflow-hidden border rounded-xl">
        <div className="relative bg-warm-gray group">
          {!available ? (
            <div className="absolute inset-0 bg-white opacity-50" />
          ) : null}
          <img
            src={getBadgeImageUrl(workoutId)}
            className={cx("px-4 py-4 rounded", {
              "blur-md": !available,
              "group-hover:scale-105 transition-transform": available,
            })}
          />
        </div>
      </div>
      <div className="flex-col py-2">
        {claimed ? (
          <div className="flex items-center justify-between py-1 font-bold text-gray-800">
            <div className="items-center hidden space-x-2 md:flex">
              <CheckCircleIcon className="w-5 h-5 text-cobalt" />
              <span className="">Claimed</span>
            </div>
            <div className="flex items-center justify-end w-full space-x-1 md:pr-1 md:space-x-2">
              <a
                // href="#"
                // target="_blank"
                // rel="noreferrer"
                title="not yet available"
                className="px-2 cursor-not-allowed md:p-1 hover:opacity-75"
              >
                <img
                  className="w-5 h-5"
                  src={`/assets/external/rainbow-logo-sm.png`}
                />
              </a>
              <a
                href={openSeaUrl}
                target="_blank"
                rel="noreferrer"
                className="px-2 md:p-1"
              >
                <img
                  className="w-5 h-5"
                  src={`/assets/external/opensea-logo-sm.png`}
                />
              </a>
            </div>
          </div>
        ) : available ? (
          <div
            style={{
              padding: 3,
              background:
                "linear-gradient(90deg, #E97B88 0%, #78AAFF 56.25%, #A280ED 100%)",
              boxShadow:
                "0px 0.564654px 1.12931px rgba(0, 0, 0, 0.45), 0px 0.564654px 9.14px rgba(255, 255, 255, 0.7)",
            }}
            className={cx("transition-transform rounded-lg group", {
              "opacity-50 cursor-not-allowed": error || disabled,
              "hover:scale-105": !error && !disabled,
            })}
          >
            <button
              disabled={disabled}
              onClick={completeWorkout}
              className={cx(
                "w-full px-4 py-1 space-x-2 text-sm text-white transition-opacity bg-black rounded-lg flex-center",
                {
                  "bg-opacity-75 group-hover:opacity-75": !disabled,
                }
              )}
            >
              {loading || localLoading || !!hash ? (
                <>loading...</>
              ) : (
                <>
                  {error ? (
                    <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                  ) : null}
                  <span>Claim NFT</span>
                </>
              )}
            </button>
          </div>
        ) : (
          <div className="px-4 py-2 text-sm text-white bg-gray-500 rounded-lg opacity-75 cursor-not-allowed md:text-md flex-center">
            <span className="group-hover:hidden">Locked!</span>
            <span className="hidden group-hover:block">
              Complete workout to unlock!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
