import { useWeb3React } from "@web3-react/core";
import { useQuery } from "react-query";
import { useEffect } from "react";
import {
  DEFAULT_STATE,
  ERROR_STATE,
  useNotification,
} from "~/containers/notification";

export const useMyBadges = () => {
  const { account } = useWeb3React();
  const { setState: setNotificationState, state } = useNotification();

  const { data, refetch, isLoading, isLoadingError } = useQuery(
    [`fetchMyBadges`, account],
    async () => {
      if (!account) return {};
      const response = await fetch(`/api/fetchMyBadges/${account}`);

      if (response.status !== 200) {
        throw new Error("unable to fetch badges");
      }

      const { badgeIds } = await response.json();

      return badgeIds;
    }
  );

  useEffect(() => {
    if (isLoadingError) {
      setNotificationState(ERROR_STATE);
    } else if (state === ERROR_STATE) {
      setNotificationState(DEFAULT_STATE);
    }
  }, [isLoadingError, state]);

  return {
    data,
    refetch,
    isLoading,
  };
};
